//Custom variables

// Descriptive color variables

// Functional color variables
$color-1st : #000;

//blue
$color-2st: #00f7f7;

//yellow
$color-3st : #ff0;

//blue red
$color-4st : #e1002a;

// Font stacks
$font-1st : 'font-family: 'Roboto', sans-serif;';

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$font-supperbold: 900;

// Asset paths
$path-image   :   '../img';
$path-fonts     :  '../fonts';
