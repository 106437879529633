/*main menu*/
@media(min-width: 992px){
	ul.main-menu{
		width: 100%;
		padding: 0px;
		margin: 0px;
		list-style: none;
		text-align: center;
		li{
			position: relative;
			display: inline-table;
			a{
				color: #fff;
				font-size: rem-calc(16);
				text-transform: uppercase;
				display: inline-block;
				padding: 0px 15px;
				line-height: 55px;
				font-weight: $font-bold;
				position: relative;
				&:after{
					content: "";
					position: absolute;
					width: 0px;
					height: 2px;
					background-color: $color-3st;
					left: 0%;
					bottom: 0px;
				}
				&:hover{
					color: $color-3st;
					&:after{
						width: 50%;
						transition:all 0.4s ease 0s;
						-moz-transition:all 0.4s ease 0s;
						-webkit-transition:all 0.4s ease 0s;
						-ms-transition:all 0.4s ease 0s;
					}
				}
			}
			&:hover .main-menu-sub1{
				opacity: 1;
				visibility: visible;
				transform: translate(0, 0px);
			}
			.main-menu-sub1{
				position: absolute;
				left: 0px;
				top: 100%;
				background-color: #fff;
				width: 250px;
				z-index: 999;
				display: block;
				opacity: 0;
				visibility: hidden;
				padding: 15px 0px;
				box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
				transform: translate(0, 15px);
				transition: opacity 200ms ease-in, transform 200ms ease-in, visibility 200ms ease-in;
				li{
					width: 100%;
					display: inline-block;
					position: relative;
					a{
						color: $color-1st;
						padding: 12px 15px;
						padding-right: 5px;
						line-height: 18px;
						display: inline-block;
						width: 100%;
						font-weight: $font-regular;
						font-size: rem-calc(15);
						text-align: left;
						text-transform: none;
						&:after{
							display: none;
						}
						&:hover{
							color: $color-2st;
						}
					}
					// .main-menu-sub2{
					// 	position: absolute;
					// 	left: 100%;
					// 	top: 0px;
					// 	background-color: #fff;
					// 	width: 200px;
					// 	z-index: 999;
					// 	display: block;
					// 	opacity: 0;
					// 	visibility: hidden;
					// 	border: 1px solid #ddd;
					// }
					// &:hover .main-menu-sub2{
					// 	opacity: 1;
					// 	visibility: visible;
					// }
				}

			}
		}
	}
}
@media (max-width: 991px){
	#menu{
	    position: fixed;
	    padding: 0;
	    -ms-transform: translateX(280px);
	    -webkit-transform: translateX(280px);
	    transform: translateX(280px);
	    background-color: #000;
	    min-height: 100%;
	    height: 100vh;
	    z-index: 99999;
	    width: 280px;
	    right:0;
	    top: 0;
	    transition: all 0.3s ease;
	    opacity: 1;
	    overflow-x: scroll;
	    // box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.2) inset;
	}
	#menu ul {
	  	list-style: none;
	  	margin-top: 0;
	  	padding: 0;
	  	width: 100%;
	  	li{
	  		width: 100%;
		  	a{
		  		color: #fff;
			    display: inline-block;
			    width: 100%;
			  	padding: 10px 0 10px 25px;
			  	text-decoration: none;
			  	transition: all 0.3s ease;
			  	line-height: 1.8;
		  	    border-top: 1px solid rgba(255, 255, 255, 0.1);
    			border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    			font-weight: bold;
    			text-transform: uppercase;
    			position: relative;
			    span{
			    	position: absolute;
				    left: 0;
				    font-size: 1.125rem;
				    width: 100%;
				    height: 40px;
				    text-align: right;
				    line-height: 40px;
				    padding-right: 15px;
				    top: 0px;
			    }
			    &.active+ul{
					display: block;
			    }
		  	}
		  	ul{
		  		margin-top: 0;
		  		visibility: visible;
  				display: none;
  				position: relative;
  				opacity: 1;
  				left: 0px;
  				li{
  					border-bottom: none;
  					a{
  						color: #fff;
  						font-weight: normal;
  						padding-left: 50px;
  						text-transform: none;
  						&:hover{
  							background-color: #fff;
  						}
  					}
  					// ul{
  					// 	display: block !important;
  					// 	li{
  					// 		a{
  					// 			padding-left: 35px;
  					// 			color: #f00;
  					// 		}
  					// 	}
  					// }
  				}
		  	}
	  	}
	  	// a{
	  	// 	span[class*='fa-caret'] { float: right; }
	  	// 	&.active,
	  	// 	&:hover{
				// background: rgba(0, 0, 0, 0.04);
	  	// 	}
	  	// }
	}
	// .support-menu{
	//     display: block;
	//     padding: 0px 15px;
	//     font-size: 14px;
	//     display: inline-block;
	//     width: 100%;
	//     padding-top: 15px;
	//     margin-top: 5px;
	//     li{
	//     	color: #333;
	//     	display: inline-block;
	//     	width: 100%;
	//     }
	// }
	// .logo-menu-mobile{
	// 	background-color: #fff;
	// 	padding: 5px 15px;
	// 	border-bottom: 1px solid #eee;
	// 	img{
	// 		width: 50%;
	// 	}
	// }
	// @media (max-width: 767px){
	.button-res{
		.showmenu{
			display: block;
			padding: 5px 15px;
			position: fixed;
			z-index: 555;
			left: 0;
			right: 0;
			top:0;
			text-align: right;
			background-color: #fff;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.logo-mobile{
				img{
					width: auto;
					height: 50px;
				}
			}
			.short-menu-btn{
				i{
					font-size: rem-calc(32);
					color: $color-1st;
				}
			}
		}
	}
	// .menu-prduct{
	// 	b{
	// 		display: none;
	// 	}
	// 	.cate-menu{
	// 		display: none;
	// 	}
	// }
	// }
	// @media (min-width: 768px){
	// 	.header-main{
	// 		padding: rem-calc(20) 0;
	// 	}
	// 	.button-res{
	// 		position: absolute;
	// 		z-index: 5555;
	// 		right: 40px;
	// 		top:70px;
	// 		.showmenu{
	// 			display: block;
	// 			padding: 18px 20px;
	// 			text-align: right;
	//     		border-bottom: 0px;
	// 		}
	// 	}
	// }
}

// @media (min-width: 768px){
// }
@media (min-width: 992px){
	.button-res{
		display: none;
	}
	.header-main{
		background-color: $color-1st;
	}
}
