footer{
	padding-top: rem-calc(40);
	border: 1px solid $color-4st;
	margin-top: 15px;
	border-radius: 30px;
	font-size: rem-calc(18);
	margin-bottom: 15px;
}
.top-footer{
	.tit-footer{
		text-transform: capitalize;
		font-size: rem-calc(18);
		display: inline-block;
		width: 100%;
		padding-bottom: 10px;
		font-weight: 700;
		color: $color-2st;
		margin-bottom: 15px;
		position: relative;
		&:before{
			position: absolute;
			content: "";
			left: 0px;
			bottom: -1px;
			width: 50px;
			height: 2px;
			background-color: $color-2st;
		}
	}
	.list-footer{
		list-style: none;
		margin: 0;
		padding: 0;
		li{
			line-height: 1.7;
			a{
				display: block;
				text-decoration: none;
				font-size: rem-calc(16);
				position: relative;
				padding: 5px 0px;
				padding-left: 20px;
				display: inline-block;
				width: 100%;
				position: relative;
				color: $color-2st;
				&:hover{
					color: $color-3st;
				}
				&:before{
					content: "";
					position: absolute;
					left: 0px;
					top: 13px;
					width: 5px;
					height: 5px;
					background-color: $color-2st;
					border-radius: 5px;
				}
			}
		}
	}
}
