.header-bottom{
	padding: 10px 0px;
	background-color: $color-2st;
	.frm-search{
		width: 100%;
		display: flex;
		position: relative;
		.s-mobile{
			width: 100%;
			background-color: #fff;
			border: 2px solid $color-1st;
			border-radius: 0px;
			line-height: 36px;
			padding: 0px 15px;
		}
		.search-button{
			background-color: $color-1st;
			color: #fff;
			border-radius: 0px;
			border: none;
			position: absolute;
			right: 0px;
			line-height: 40px;
			padding: 0px 15px;
		}
	}
}
