header{
}
@media (max-width: 991px){
	header{
		padding-top: 65px;
	}
	.header-mid{
		display: none;
	}
	.header-bar{
		display: none;
	}
}
